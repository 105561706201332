import axios from "axios";
import { timer } from "rxjs";
import { registerApplication, start } from "single-spa";
const jwtDecode = require("jwt-decode");

import GlobalEventBus from "./GlobalEventBus";
const globalEventBus = new GlobalEventBus();
import GlobalEventStore from "./GlobalEventStore";
const globalEventStore = new GlobalEventStore();

// for running single spa locally, below variable should point to dev proxy
const userManagementUrl = isLocal() ? "https://dev-scheduling.uhchousecalls.com/singleSpaConfig" : "/singleSpaConfig";
const hostname = "scheduling.uhchousecalls.com";
const pageTitle = "HouseCalls Scheduling";
const nestedWidgetSectionPrefix = "router_%";
const excludedAppRegistrations = ["scheduling", "ovcp_visits", "virtual_visit", "ovc_accounts", "assessment_proxy", "system_mgmt"];
let landingAppConfig: ApplVer;
let appNames$: any;

// Example app config
// export const appConfig: ApplVer = {
//   appl_nm: "<appl_nm>",
//   appl_ver_id: "<appl_ver_id>",
//   appl_endpt_url:
//       "<appl_endpt_url>",
//   bas_mnu_url: "<bas_mnu_url>",
// };

// Example app config with nested apps
// export const appConfig: ApplVer = {
//   appl_nm: "<appl_nm>",
//   appl_ver_id: "<appl_ver_id>",
//   appl_endpt_url:
//       "<appl_endpt_url>",
//   bas_mnu_url: "<bas_mnu_url>",
//   appl: {
//     appl_widgets: [
//       {
//         appl_nm: "<appl_nm>",                    // parent app
//         widget_nm: "<widget_nm>",                // child app
//         ui_sect_nm: "<ui_sect_nm>",              // parent app section
//         widget_desc: {
//           route: "<route>",                      // named route to activate single-spa app
//           appl_endpt_url: "<appl_endpt_url>"
//         }
//       }
//     ]
//   }
// };

export const hcSchedulerConfig: ApplVer = {
  appl_nm: "hc_scheduler",
  appl_ver_id: "1.0.0",
  appl_endpt_url:"/microproduct/hc-scheduler-ui-service.housecalls-member-self-scheduler.svc.cluster.local/main.js",
  bas_mnu_url: "/",
};

export const hcDDMUiConfig: ApplVer = {
  appl_nm: "hc-digital-member-ui",
  appl_ver_id: "1.0.0",
  appl_endpt_url:"/microproduct/hc-digital-member-ui-service.housecalls-digital-delivery-materials.svc.cluster.local/main.js",
  //appl_endpt_url:"/microproduct/hc-scheduler-ui-service.housecalls-member-self-scheduler.svc.cluster.local/main.js",
  bas_mnu_url: "/hc-digital-member",
};


class AppInfo {
  name: string;
  url: string;
}

class ApplVer {
  appl_nm: string;
  appl_ver_id: string;
  appl_endpt_url: string;
  bas_mnu_url: string;
  appl?: Appl;
}

class Appl {
  appl_nm?: string;
  appl_widgets?: ApplWidget[];
}

class ApplWidget {
  appl_nm?: string;
  widget_nm?: string;
  widget_desc?: WidgetDesc;
  ui_sect_nm?: string;
}

class WidgetDesc {
  route: string;
  appl_endpt_url: string;
}

function init() {
  const pathName = window.location.pathname;
  console.log("pathname"+pathName);
  //removePotentialSubroute();
  landingAppConfig = getLandingPageConfig(pathName);
  console.log("landingAppConfig",landingAppConfig)
  const element = document.getElementById("page-title");
  if (element) {
    element.innerText = pageTitle;
  }

  console.log("single-spa divs loaded:");
  console.log(document.getElementsByClassName("spa-app"));

  registerSpaAndWatchForToken(landingAppConfig);
}


/**
 * determines which app to load as the 'shell' app
 * allows multiple entry points
 * @param pathName
 */
export function getLandingPageConfig(pathName) {
  if (pathName.includes(hcDDMUiConfig.bas_mnu_url)) {
    return hcDDMUiConfig;
  }else {
    return hcSchedulerConfig;
  }
}

/**
 * remove any potential name router outlets from the url since loading those during page refresh is causing micro products to load inconsistently within the page
 * @example: member-profile/dashboard(aux-route:route-name)?values=aW5ka -> /member-profile/dashboard?values=aW5ka
 */
export function removePotentialSubroute() {
  const namedRouteRegex = /\((.*)\)/; // matches angular named route pattern
  const matchedAuxiliaryRoute = window.location.pathname.match(namedRouteRegex);
  if (matchedAuxiliaryRoute && matchedAuxiliaryRoute[0]) {
    window.location.pathname = window.location.pathname.replace(matchedAuxiliaryRoute[0], '');
  }
}



function registerSpaAndWatchForToken(config: ApplVer) {
  registerSingleSpaApplication(config);
  watchEcpTokenInStorage();
}

/**
 * Register apps
 * - Running local will point to dev env or local main.js files if they are used.
 */
export function registerSingleSpaApplication(app: ApplVer) {
  if (app.appl_endpt_url && app.bas_mnu_url) {
    // eslint-disable-next-line no-console
    console.log("Registering app:", app.appl_nm);
    registerApplication({
      name: app.appl_nm,
      app: () =>  {
        if (isLocal()) {
          return app.appl_endpt_url.startsWith('/microproduct/') ?
              System.import(`https://dev-${hostname}${app.appl_endpt_url}`) :
              System.import(`${app.appl_endpt_url}`);
        }
        return System.import(`${app.appl_endpt_url}`);
      },
      activeWhen: app.bas_mnu_url,
      customProps: { globalEventBus, globalEventStore },
    });

    // loop through the appl_widgets and look for nested applications to mount
    app?.appl?.appl_widgets.forEach(widget => {
      if(widget?.widget_desc?.appl_endpt_url && widget?.widget_desc?.route) {
        registerWidget(widget, app);
      }
    })
  }
}

/**
 * register a nested widget, matching on named angular router
 * widgets are named and linked to parent mp path
 * @param widget
 * @param parentApp
 */
export function registerWidget(widget: ApplWidget, parentApp: ApplVer) {
  const nestedRoute = getNestedRoute(widget, parentApp.bas_mnu_url);
  const applUrl = widget?.widget_desc?.appl_endpt_url;
  const appName = `widget_${parentApp?.appl_nm}_${widget?.widget_nm}`;
  if (nestedRoute && applUrl) {
    console.log('Registering nested app:', appName, 'at', nestedRoute)
    registerApplication({
      name: appName,
      app: () =>  {
        if (isLocal()) {
          return applUrl.startsWith('/microproduct/') ?
              System.import(`https://dev-${hostname}${applUrl}`) :
              System.import(`${applUrl}`);
        }
        return System.import(`${applUrl}`);
      },
      activeWhen: (location: Location) =>  {
        return location.pathname.match(nestedRoute) !== null;
      },
      customProps: { globalEventBus, globalEventStore }
    });
  }
}

start({ urlRerouteOnly: true });

init();

export function watchEcpTokenInStorage(): void {
  const ecp_token = localStorage.getItem("ecp_token");
  if (!ecp_token || window.location.pathname === '/sso') {
    appNames$ = timer(200);
    appNames$.subscribe(() => {
      watchEcpTokenInStorage();
    });
  } else {
    loadUsersApps(ecp_token);
  }
}

function loadUsersApps(ecp_token: string) {
  const decoded_ecp_token = jwtDecode(ecp_token);
  const cli_orgs_array = decoded_ecp_token["x-ecp-claims"]["x-ecp-cli-orgs"];
  const cli_orgs: string[] = [];
  const func_roles: string[] = [];
  cli_orgs_array.forEach((cli_org_object) => {
    cli_orgs.push(cli_org_object["org-id"]);
    const func_roles_array = cli_org_object["func-roles"];
    func_roles_array.forEach((func_role_object) => {
      func_roles.push(func_role_object["role-name"]);
    });
  });
  if (cli_orgs.length && func_roles.length) {
    axios.post(userManagementUrl, getUsersApps(cli_orgs, func_roles), getUserAppsHeaders())
        .then((response) => {
          const apps = response["data"]["data"]["cli_func_role_appl"];
          // filter and map nested micro products for loading
          const app_names = apps
              .filter((app) => landingAppConfig.appl_nm !== app.appl_ver.appl_nm && app.appl_ver.appl_endpt_url && app.appl_ver.bas_mnu_url)
              .map(app => ({ name: app.appl_ver.appl_nm, url: app.appl_ver.bas_mnu_url }))
          localStorage.setItem("app_names", JSON.stringify(app_names));
          appNames$ = null;
          if (apps && apps.length > 0) {
            // wait for shell app to load nested apps in DOM before registering with single-spa
            waitForElement(`[id^='single-spa-application'] [id^='single-spa-application']`).then(() => {
              apps.forEach((appConfig) => {
                // don't register the same app that was already registered initially OR ones that should be excluded
                if (landingAppConfig.appl_nm !== appConfig.appl_ver.appl_nm && !excludedAppRegistrations.includes(appConfig.appl_ver.appl_nm)) {
                  registerSingleSpaApplication(appConfig.appl_ver);
                }
              });
            });
          }
        }).catch(function (error) {
          console.error(`Request to ${userManagementUrl} failed. \n\n ${error.response}. \n\n Micro products will not load correctly.`);
        });
  }
}

/**
 * waits for element(s) of provided selector
 * used to prevent race conditions with nested micro products
 * @param selector
 */
export async function waitForElement(selector: string): Promise<any> {
  const start = new Date();
  while ( document.querySelector(selector) === null) {
    await new Promise( resolve => requestAnimationFrame(resolve) )
  }
  console.log(`${selector} took: ${(new Date()).valueOf() - (start).valueOf()} ms to load`) ;
  return document.querySelector(selector);
}

/**
 * get all the apps user in context has client/role access for
 * appl_widgets used for nested apps, requires ui_sect_nm starts with `router_`
 * @param cli_orgs
 * @param func_roles
 */
export function getUsersApps(cli_orgs: string[], func_roles: string[]) {
  return {
    // do we want order_by: {appl_ver_id: desc}?
    query: `
            query get_users_apps {
                cli_func_role_appl(distinct_on: appl_nm, where: {cli_org_id: {_in: ${JSON.stringify(cli_orgs)}}, func_role_nm: {_in: ${JSON.stringify(func_roles)}}}) {
                    appl_ver {
                        appl_endpt_url
                        appl_nm
                        bas_mnu_url
                        appl_ver_id
                        appl {
                          appl_widgets(where: {ui_sect_nm: {_like: "${nestedWidgetSectionPrefix}"}}) {
                            widget_nm
                            widget_desc
                            ui_sect_nm
                          }
                        }
                    }
                }
            }
        `,
    variables: {},
  };
}

/**
 * headers for retrieving apps user is authorized to access
 */
export function getUserAppsHeaders() {
  const headers = {
    "content-type": "application/json",
    // "Authorization": `Bearer ${localStorage.getItem("ecp_token")}`
    // if ever decide to use Authorization header, x-hasura-role header will need to be added and value determined
  };
  return { headers: headers };
}

/**
 * determine if we are running localhost, maybe this should be env var instead?
 */
export function isLocal(): boolean {
  return window.location.host.startsWith('localhost');
}

/**
 * nested routes use a named angular router outlet
 * they follow the pattern of [parent base url]([outlet name]:[route-path]/[optional-child-path(s)]) in the url
 * @example: /parent-route(ovc_scheduling:route-1/route-2)
 * @param widget
 * @param parentBaseUrl
 * @return nestedRoute, string used to regex if named outlet matches, works with multiple outlets
 */
export function getNestedRoute(widget: ApplWidget, parentBaseUrl: string): string {
  let nestedRoute: string = null;
  const auxRoute = widget?.widget_desc['route'] || null;
  const namedRouteRegex: RegExp = /\((.*):(.*)\)/;                                         // matches angular named route pattern
  const match = auxRoute?.match(namedRouteRegex);
  if (auxRoute && match !== null) {
    nestedRoute = `.*${escapeRegex(parentBaseUrl)}.*(.*${match[1]}:${match[2]}.*)`;      // matcher for single-spa activate function
  }
  return nestedRoute;
}

/**
 * regex escape a string
 * @param string
 */
export function escapeRegex(string): string {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}