export default class GlobalEventStore {
  store: any;
  add: any;
  get: any;
  remove: any;

  constructor() {
    this.store = {};

    this.add = (key, value) => {
      console.log(`adding key ${key} with value ${JSON.stringify(value)}`);
      this.store[key] = value;
    };

    this.get = (key) => {
      console.log(`getting key ${key}`);
      return this.store[key];
    };

    this.remove = (key) => {
      console.log(`removing key ${key}`);
      this.store[key] = undefined;
    };
  }
}
